.breadcrumb {
    background-color: inherit;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0.25rem;
    @media (min-width: 992px) {
        height: 36px;
    }

    .breadcrumb-item:first-child > a,
    .breadcrumb-item:first-child > div {
        padding: 0.5rem 0.75rem 0.5rem 0;
    }

    .breadcrumb-item a,
    .breadcrumb-item > div {
        color: $color-text-secondary;
        text-decoration: none;
        padding: 0.5rem 0.75rem;
        &:hover {
            color: $color-text-primary;
        }
    }

    .breadcrumb-item.active a,
    .breadcrumb-item.active > div {
        color: $color-text-primary;
        &:hover {
            color: $color-text-primary;
        }
    }
}

.breadcrumb-more-items {
    background-color: white;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid $color-body-bg;

    @media (min-width: 992px) {
        border: none;
    }

    .breadcrumb-item {
        + .breadcrumb-item {
            position: relative;
            height: 36px;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                background-image: url('../images/breadcrumb.svg');
                background-repeat: no-repeat;
                background-size: contain;
                height: 100%;
                @media (min-width: 992px) {
                    margin: auto;
                    left: -0.5rem;
                    top: 0.4rem;
                    background-image: url(../images/chevron-right-gray.svg);
                    height: 1.5rem;
                    width: 40px;
                }
            }
        }

        &.active a {
            cursor: default;
        }
    }
}

.breadcrumb-more-items > .container > .breadcrumb > .breadcrumb-item {
    @media (min-width: 992px) {
        margin: auto !important;
    }
    @media (max-width: 991px) {
        display: flex;
    }
}

.breadcrumb-item > a {
    @media (min-width: 992px) {
        display: block;
    }
}
