/**
 * Body
 */
body {
    background-color: $color-body-bg;
    color: $color-body-text;
    font-family: $font-family-primary;
    font-size: 0.875rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-top: 5.125rem;

    @media (min-width: 992px) {
        padding-top: 0;
    }

    &.not-logged {
        padding-top: 9.958rem;
        @media (min-width: 992px) {
            padding-top: 0;
        }
    }
}

p,
li,
label {
    font-size: 1rem;
    @media (max-width: 991px) {
        font-size: 0.9rem;
    }
}

small,
.small {
    font-size: 0.875rem;
}

.bigger {
    font-size: 1.05rem;
}

.big {
    font-size: 1.5rem;
}

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $color-text-primary;
    font-family: $font-family-primary;
    font-weight: bold;
}

h1 {
    font-size: 40px;
    letter-spacing: -0.56px;
    line-height: 46px;
}

h2 {
    font-size: 28px;
    letter-spacing: -0.39px;
    line-height: 29px;
    margin-bottom: 16px;
}

h3 {
    font-size: 21px;
    letter-spacing: -0.29px;
    line-height: 22px;
}

h4 {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
}

.forms-headline {
    color: $color-text-secondary;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.87px;
    text-transform: uppercase;
    line-height: 15px;
    margin-bottom: 0;
}

/**
 * Text emphasis
 */
.text-primary {
    color: $color-text-primary !important;
}

.text-secondary {
    color: $color-text-secondary !important;
}

.text-warning {
    color: $color-warning !important;
}

.text-danger {
    color: $color-danger !important;
}

.text-transform-unset {
    text-transform: unset !important;
}

.font-sm-bold {
    font-weight: normal;
    padding-bottom: unset;
    @media (max-width: 991px) {
        font-weight: bold;
        padding-bottom: 5px;
    }
}

/**
 * Links
 */
a {
    color: $color-link-hover;
    text-decoration: underline;

    &:hover {
        color: $color-link-hover;
        text-decoration: none;
        i {
            color: $color-text-primary !important;
        }
    }

    &:active {
        text-decoration: underline;
    }
}

p a {
    color: $color-link;
}
