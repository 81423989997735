/**
 * Body and text
 */
$color-body-bg: #f0f0f0;
$color-body-text: #4a4a4a;
$color-bg-white: #ffffff;
$color-text-white: #ffffff;
$color-text-primary: #0b0b0b;
$color-text-secondary: #777777;

/**
 * Navigation
 */
$color-nav-bg: #ffd500;
$color-nav-text: #7b713e;

/**
 * Links and buttons
 */
$color-link: #4a4a4a;
$color-link-hover: #0b0b0b;
$color-btn-primary-bg: #0b0b0b;
$color-btn-primary-bg-hover: #4a4a4a;
$color-btn-secondary-bg: #777777;
$color-btn-secondary-bg-hover: #a6a6a6;
$color-btn-success-bg: #11ac4e;
$color-btn-success-bg-hover: #19c95e;
$color-btn-light: #000000;
$color-btn-light-bg: #d3d3d3;
$color-btn-light-bg-hover: #e6e6e6;
$color-btn-link: #000000;

/**
 * Form inputs
 */
$color-input-text: #0b0b0b;
$color-input-bg: #ffffff;
$color-input-border: #777777;
$color-input-focus: #3487ff;

/**
 * Notifications and labels
 */
$color-info: #3487ff;
$color-warning: #e56b2c;
$color-success: #11ac4e;
$color-danger: #dc3c3c;
$color-label-ng: #ffeda4;
$color-label-ec: #bbe2fb;

/**
 * Tables
 */
$color-table-action: #f3f8ff;
