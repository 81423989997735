/**
 * Datepicker input
 */
.datepicker {
    .form-control {
        border: 0 solid $color-body-bg;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

        &.datepicker-opened {
            border-radius: 5px 5px 0 0;
            background-color: $color-nav-bg;

            &:focus {
                border: 0 solid $color-nav-bg;
                box-shadow: none;
            }
        }
    }

    &:after {
        pointer-events: none;
        @include mixin-bg-icon;
        content: $icon-calendar;
        color: $color-text-primary;
        font-size: 1.25rem;
        right: 1.25rem;
        top: calc(50% - 0.625rem);
    }
}

.datepicker-group {
    display: flex;

    .datepicker {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;

        &:first-child {
            padding-right: 0;

            .form-control {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &:last-child {
            padding-left: 0;

            .form-control {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left-width: 1px;
            }
        }
    }
}

/**
 * Datepicker calendar
 */
#ui-datepicker-div {
    background-color: $color-nav-bg;
    color: $color-text-primary;
    border-radius: 0 0 5px 5px;
    width: 100%;

    .ui-datepicker-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;
        font-weight: bold;
        line-height: 1rem;
        margin: 1.75rem 1rem 0 1rem;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        display: block;
        cursor: pointer;

        span {
            display: inline-block;
            width: 1rem;
            height: 1rem;
            @include mixin-bg-icon;
            position: static;
        }
    }

    .ui-datepicker-prev {
        order: 1;
        padding-left: 1rem;
        padding-right: 1.5rem;
        @media (max-width: 500px) {
            padding: 0px !important;
        }
    }

    .ui-datepicker-next {
        order: 3;
        padding-right: 1rem;
        padding-left: 1.5rem;
        @media (max-width: 500px) {
            padding: 0px !important;
        }
    }

    .ui-datepicker-title {
        order: 2;
        text-transform: capitalize;
    }

    .ui-datepicker-calendar {
        width: calc(100% - 2rem);
        margin: 1rem;

        td,
        th {
            text-align: center;

            @media (min-width: 375px) {
                padding: 0.625rem;
            }

            a {
                text-decoration: none;
            }
        }

        th,
        .ui-datepicker-other-month {
            color: $color-nav-text;
        }

        .ui-datepicker-today {
            padding: 0;

            a {
                display: inline-block;
                color: $color-nav-bg;
                background-color: black;
                border-radius: 50%;
                width: 1.5rem;
                height: 1.5rem;
                line-height: 1.5rem;

                @media (min-width: 375px) {
                    width: 2rem;
                    height: 2rem;
                    line-height: 2rem;
                }
            }
        }

        .ui-datepicker-week-end {
            font-weight: bold;
        }
    }
}

/**
 * Valid and invalid input
 */
.datepicker > input {
    &.form-control.is-valid,
    &.form-control.is-invalid {
        background-position: right 48px center;
    }
}

.validated-datepicker {
    &.form-group {
        input:focus.form-control {
            border: none;
            box-shadow: none;
        }

        input.is-invalid {
            border: 1px solid $color-danger;
            &:focus {
                border: 1px solid $color-danger;
                box-shadow: 0 0 2px 0 rgba($color-danger, 0.75);
            }
        }

        input.is-valid {
            border: 1px solid $color-success;
            &:focus {
                border: 1px solid $color-success;
                box-shadow: 0 0 2px 0 rgba($color-success, 0.75);
            }
        }

        .invalid-feedback {
            margin-right: 2rem;
        }
    }
}

.long-error {
    transform: none !important;
}
