.table-responsive,
.table {
  border-radius: 5px;
  overflow-x: visible;
  overflow-y: visible;
}

.table {
  margin-bottom: 0;

  td,
  th {
    border-color: $color-body-bg;
    color: $color-text-primary;
    font-size: 15px;
    vertical-align: middle;
    padding: 1rem;

    @media (min-width: 992px) {
      padding: .875rem .25rem .875rem .875rem;
    }

    &:first-child {
      padding-left: 1.25rem;
    }

    &:last-child {
      padding-right: 1.25rem;
    }
  }

  th.sortable {
    cursor: pointer;
    >svg {
      visibility: collapse;
    }
    &.ASC, &.DESC {
      background: #595959;
    }
    &:hover {
      background: #4D4D4D;
    }
    &:hover:not(.ASC),:hover:not(.DESC) {
      .ASC {
        visibility: visible;
        opacity: 0.5;
      }
    }
    &.ASC>svg.ASC {
      visibility: visible;
      opacity: 1;
    }
    &.DESC>svg.DESC {
      visibility: visible;
      opacity: 1;
    }
  }

  .thead-dark,
  .thead-light {
    th {
      font-size: 13px;
      letter-spacing: .87px;
      text-transform: uppercase;

      .control-label {
        text-transform: initial;
        font-weight: normal;
      }
    }
  }

  .thead-dark th, .thead-dark tr {
    background-color: $color-btn-secondary-bg;
    border-color: $color-btn-secondary-bg;
    color: $color-body-bg;
  }

  .thead-light th, .thead-light tr {
    background-color: $color-body-bg;
    border-color: $color-body-bg;
    color: $color-text-primary;
  }
}

/**
 * Table with action
 */
.table-action {
  display: block;
  background-color: transparent;

  > thead,
  > tbody {
    display: block;

    > tr {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: $color-bg-white;
      border-radius: 5px;
      cursor: pointer;
      position: relative;

      &:hover {
        background-color: $color-table-action;
      }

      &:before {
        @include mixin-bg-icon;
        font-size: 1.1rem;
        content: $icon-chevron-right-16;
        color: $color-btn-secondary-bg-hover;
        top: 1.125rem;
        right: .75rem;
      }

      > td,
      > th {
        display: block;
        border: none;
      }

      + tr {
        margin-top: .5rem;
      }
    }
  }

  @media (min-width: 992px) {
    display: table;

    > thead,
    > tbody {
      display: table-row-group;

      > tr {
        display: table-row;
        border-radius: 0;

        &:before {
          content: none;
        }

        > td,
        > th {
          display: table-cell;
          border-top: 1px solid $color-body-bg;
        }

        + tr {
          margin-top: 0;
        }
      }
    }

    strong {
      font-weight: normal;
    }
  }
}

/**
 * Table switch grid / list view
 */
.table-switch {
  display: table;

  > thead,
  > tbody {
    display: table-row-group;

    > tr {
      display: table-row;
      border-radius: 0;

      &:before {
        content: none;
      }

      &:first-of-type {
        display: none;

        &.filter {
          display: table-row;
        }
      }

      > td,
      > th {
        display: table-cell;
        border-top: 1px solid $color-body-bg;
      }

      + tr {
        margin-top: 0;
      }
    }
  }

  &.grid {
    display: block;
    background-color: transparent;
    overflow: hidden;

    > tbody {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      @media (min-width: 768px) {
        margin-left: -0.25rem;
        margin-right: -0.25rem;
      }

      @media (min-width: 992px) {
        margin-left: -0.25rem;
        margin-right: -0.25rem;
      }

      > tr {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: $color-bg-white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        padding: 1.25rem;
        margin: .25rem;
        width: 100%;

        @media (min-width: 768px) {
          width: calc(50% - .5rem);
        }

        @media (min-width: 992px) {
          width: calc(33.333% - 0.5rem);
        }

        &:before {
          @include mixin-bg-icon;
          content: $icon-chevron-right-16;
          color: $color-btn-secondary-bg-hover;
          top: 1.125rem;
          right: .75rem;
        }

        &:hover {
          background-color: $color-table-action;
        }

        &:first-of-type {
          display: none;
        }

        th {
          display: none;
        }

        td {
          &:nth-of-type(1) {
            padding: 0 0 .875rem 0;
            border: none;
          }

          &:nth-of-type(2) {
            padding: 0;
            display: inherit;
            border: none;
          }

          &:nth-of-type(3) {
            padding: 0;
            position: absolute;
            right: .75rem;
            bottom: 1.25rem;
            border: none;
          }

          &:nth-of-type(4) {
            display: none;
          }

          &.text-nowrap.text-right {
            display: none;
          }
        }
      }
    }

    > thead {
      display: none;
    }
  }
}