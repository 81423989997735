.page-link {
  color: $color-text-primary;
  background-color: transparent;
  border-color: transparent;
  padding: .3125rem .25rem;
  margin-left: 0;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.9rem;

  &.active, &.active:hover {
    color: $color-text-primary;
    text-decoration: none;
    background-color: transparent;
    border: 2px solid transparent;
    border-bottom-color: $color-btn-primary-bg;
  }

  &:hover {
    color: $color-text-primary;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom-color: $color-btn-primary-bg;
  }
}

.page-item {
  margin: 0 .25rem;

  &:first-child,
  &:last-child {
    .page-link {
      color: $color-text-primary;
      border: 2px solid $color-btn-primary-bg;
      border-radius: 5px;
      padding: .25rem .4375rem;
    }
  }

  &:first-child {
    margin-right: 1rem;
  }

  &:last-child {
    margin-left: 1rem;
  }

  &.disabled {
    .page-link {
      background-color: transparent;
      opacity: .2;
    }
  }
}