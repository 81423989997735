/**
 * Main navigation
 */
.nav-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1049; // z-indexe must be in range: 1000(loading-indicator-mask) - 1050(modal-mask)

    @media (min-width: 992px) {
        position: relative;
    }
}

.navbar-fullScreenCustomerRequest {
    @media (min-width: 992px) {
        padding-top: 0px !important;
    }
}

.navbar {
    padding-top: 20px;

    @media (min-width: 992px) {
        padding-top: 40px;
        padding-bottom: 10px;
    }

    & .dropdown.show:after {
        opacity: 0;
    }

    a {
        text-decoration: none;
    }

    .navbar-collapse {
        height: 0;
        @media (max-width: 991px) {
            margin-top: 10px;
            border-top: 1px solid rgba(11, 11, 11, 0.08);
            &.collapsing {
                height: 100vh;
            }
            &.collapse.show {
                height: 100vh;
                max-height: 100vh;
                overflow-x: hidden;
                overflow-y: auto;
            }
            & .navbar-nav {
                padding-left: 30px !important;
            }
            & .upper-level {
                padding-left: 30px !important;
                height: 320px;
            }
        }

        .not-logged & {
            $screen-minus-navbar-and-login: calc(100vh - 170px);

            &.collapsing {
                height: $screen-minus-navbar-and-login;
            }

            &.collapse.show {
                height: $screen-minus-navbar-and-login;
            }
        }
    }

    .badge-primary {
        background-color: $color-btn-primary-bg;
        color: $color-nav-bg;
        border-radius: 50%;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        width: auto;
        min-width: 24px;
        height: 24px;
        transform: translateY(-2px);
    }

    .badge-secondary {
        height: 20px;
        width: 20px;
        display: inline;
        padding: 0;
        color: black;
        background-color: white;
        border-radius: 25px;
        position: absolute;
        left: 15px;
        top: 4px;
        -webkit-box-shadow: 8px 8px 13px 0px rgba(133, 131, 133, 0.5);
        -moz-box-shadow: 8px 8px 13px 0px rgba(133, 131, 133, 0.5);
        box-shadow: 8px 8px 13px 0px rgba(133, 131, 133, 0.5);

        &:empty {
            display: block;
        }

        @media (max-width: 991px) {
            position: absolute;
            left: 7px;
            top: 7px;
        }
    }

    .badge-third {
        height: 10px;
        width: 10px;
        display: inline;
        padding: 0;
        color: black;
        background-color: black;
        border-radius: 25px;
        position: absolute;
        left: 33px;
        top: 9px;
        -webkit-box-shadow: 8px 8px 13px 0px rgba(133, 131, 133, 0.5);
        -moz-box-shadow: 8px 8px 13px 0px rgba(133, 131, 133, 0.5);
        box-shadow: 8px 8px 13px 0px rgba(133, 131, 133, 0.5);

        &:empty {
            display: block;
        }

        @media (max-width: 991px) {
            position: absolute;
            left: 34px;
            top: 10px;
        }
    }

    .dropdown {
        min-width: 6rem;

        @media (min-width: 992px) {
            min-width: auto;
        }
    }

    .dropdown-menu {
        background-color: $color-bg-white;
        width: 100%;
        top: auto;
        height: 100vh;
        max-width: unset;
        @media (min-width: 768px) {
            width: 70vw;
            max-width: 500px;
            height: auto;
            margin-top: 0.5rem;
        }

        &.notification-menu:before {
            left: unset;
            right: 8.6rem;
            @media (min-width: 992px) {
                left: auto;
                right: 0.5rem;
            }
        }

        &:before {
            left: unset;
            right: 8.6rem;
            @media (min-width: 992px) {
                left: auto;
                right: 2.5rem;
            }
        }

        .dropdown-body {
            position: relative;
            border-radius: 0.25rem;
            max-height: calc(100vh - 164px);
            overflow-y: auto;

            @media (min-width: 768px) {
                max-height: 70vh;
            }

            .dropdown-item {
                &.active,
                &:active {
                    color: $color-text-primary;
                    background-color: $color-bg-white;
                }
            }

            .dropdown-item.bg-secondary:hover {
                background-color: $color-body-bg !important;
            }
        }
    }

    .dropdown-item {
        &:focus,
        &:hover {
            text-decoration: none;

            > span,
            > div {
                text-decoration: underline;
            }
        }
    }
}

.navbar-brand {
    color: $color-text-primary;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.25px;
    line-height: 42px;
    margin-right: 0;
    padding-left: 30px;

    @media (min-width: 375px) {
        margin-right: 1rem;
        padding-left: 30px;
    }
    @media (min-width: 992px) {
        padding-left: 0;
    }

    &.btn {
        color: $color-text-white;
        font-size: 0.875rem;
        line-height: 1.75rem;
        text-decoration: none;

        @media (min-width: 992px) {
            padding-left: 15px;
        }

        &:hover,
        &:focus,
        &:active {
            color: $color-text-white;
        }
    }
}

.navbar-brand img {
    margin-bottom: 6px;
    height: 100%;
}

.navbar-brand-fullScreenCustomerRequest {
    @media (min-width: 992px) {
        margin-bottom: 0px !important;
        margin-top: 6px !important;
    }
}

.navigation-divider {
    border-color: rgba($color-input-text, 0.08);
    margin-right: -15px;
    margin-left: -15px;
}

.navbar-light .navbar-nav .nav-link {
    position: relative;
    color: $color-text-primary;
    font-size: 21px;
    letter-spacing: -0.29px;
    line-height: 22px;
    display: inline;

    &:hover {
        text-decoration: none;
        font-weight: bold;
    }

    &.active {
        border-bottom: none;
        font-weight: bold;

        @media (min-width: 992px) {
            border-bottom: 2px solid $color-btn-primary-bg;
            font-weight: normal;
        }
    }

    @media (min-width: 992px) {
        font-size: 18px;
        letter-spacing: -0.25px;
        line-height: 42px;
        color: $color-text-primary;
        position: relative;
        &:hover {
            font-weight: normal;
            color: $color-text-primary;
            &:after {
                display: block;
                content: '';
                background-color: #0b0b0b;
                height: 3px;
                width: 100%;
                position: absolute;
                bottom: -10px;
            }
        }
    }
}

.navbar-light .navbar-nav .link-active {
    &:after {
        @media (min-width: 992px) {
            display: block;
            content: '';
            background-color: #0b0b0b;
            height: 3px;
            width: 100%;
            position: absolute;
            bottom: -10px;
        }
    }
}

.nav-link2 {
    &:hover {
        &:after {
            bottom: -13px !important;
        }
    }
}

.navbar-light .navbar-nav .link2-active {
    &:after {
        display: block;
        content: '';
        background-color: #0b0b0b;
        height: 3px;
        width: 100%;
        position: absolute;
        bottom: -13px;
    }
}

.nav-link3 {
    &:hover {
        &:after {
            bottom: -22px !important;
        }
    }
}

.nav-link4 {
    &:hover {
        &:after {
            bottom: -19px !important;
            background-color: white !important;
        }
    }
}

.navbar-light .navbar-nav .link3-active {
    &:after {
        display: block;
        content: '';
        background-color: #0b0b0b;
        height: 3px;
        width: 100%;
        position: absolute;
        bottom: -22px;
    }
}

.navbar-light .navbar-nav .link4-active {
    &:after {
        display: block;
        content: '';
        background-color: white;
        height: 3px;
        width: 100%;
        position: absolute;
        bottom: -19px;
    }
}

#dropdownNotification {
    font-size: 18px;

    & .badge-secondary {
        margin-left: 3px;
    }

    & .row .col-12 {
        margin-top: -5px;
    }
}

#dropdownLogin {
    padding-left: 10px;
    font-size: 18px;

    &:hover {
        color: #0b0b0b !important;
    }
}

.icon-bar {
    width: 22px;
    height: 2px;
    background-color: $color-btn-primary-bg;
    display: block;
    transition: all 0.2s;
    margin-top: 4px;
}

.navbar-toggler {
    border: none;
    background: transparent;
    color: $color-text-primary;
    padding-right: 30px;

    @media (min-width: 992px) {
        padding-right: 0rem;
    }

    .navbar-text {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
    }

    .top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
    }

    .middle-bar {
        opacity: 0;
    }

    .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
    }

    &.collapsed {
        .top-bar {
            transform: rotate(0);
        }

        .middle-bar {
            opacity: 1;
        }

        .bottom-bar {
            transform: rotate(0);
        }
    }
}

.upper-level {
    color: $color-text-primary;
    display: flex;
    width: 100%;
    padding: 0 15px;
    flex-direction: column;
    position: unset;

    img {
        margin-left: 0.4rem;
        margin-right: 1.9rem;
    }

    .nav-link {
        color: $color-text-primary;
        font-size: 16px;
        letter-spacing: -0.22px;
        line-height: 42px;

        &:hover {
            text-decoration: underline;
            color: $color-text-primary;
        }
    }

    a {
        color: $color-nav-text;
    }

    @media (min-width: 992px) {
        flex-direction: row;
        position: absolute;
        top: 10px;
        left: 0;

        img {
            margin: unset;
        }

        .nav-link {
            color: $color-nav-text;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
        }
    }
}

.nav.nav-settings {
    background-color: inherit;

    .nav-link.active {
        i {
            transform: rotateZ(90deg);
        }
    }
}

/**
 * Navigation tabs
 */
.nav-pills {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid $color-btn-light-bg-hover;
    border-bottom: 0;
    background-color: $color-bg-white;

    .show > .nav-link {
        color: $color-text-primary;
        background-color: transparent;
    }

    .nav-link {
        color: $color-text-primary;
        font-weight: bold;
        letter-spacing: -0.22px;
        border-radius: 0;
        border-bottom: 1px solid $color-btn-light-bg-hover;
        background-color: transparent;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 80px;

        @media (max-width: 768px) {
            padding: 1.75rem;
        }

        &:first-child {
            margin-left: -1px;
        }

        &:last-child {
            border-bottom: none;
        }

        &.active {
            color: $color-text-primary;
            background-color: transparent;
            cursor: default;
        }

        &:not(.active):hover {
            cursor: pointer;
        }

        i[class^='icon-'] {
            font-size: 1.125rem;
            margin-right: 1rem;
        }

        &:before {
            @include mixin-bg-icon;
            content: $icon-chevron-right-16;
            color: $color-btn-secondary-bg-hover;
            right: 1.5rem;
        }

        > div {
            margin-left: 2.25rem;
        }

        @media (min-width: 768px) {
            border-bottom: none;
            border-right: 1px solid $color-btn-light-bg-hover;

            &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            &:nth-child(4) {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                border-right: none;
            }

            &.active,
            &:hover {
                box-shadow: inset 0 -4px 0 0 $color-body-text;
            }

            i[class^='icon-'] {
                margin-right: 5px;
            }

            &:before {
                content: none;
            }

            > div {
                margin-left: 0;
            }
        }
    }

    &.nav-justified {
        > .nav-link {
            flex-basis: 100%;
            text-align: left;
        }
    }

    @media (min-width: 768px) {
        &.nav-justified {
            > .nav-link {
                flex-basis: 0;
                text-align: center;
            }
        }
    }
}

.not-logged .nav-container {
    @media (max-width: 991px) {
        max-height: 100vh;
        .navbar .navbar-collapse.collapse.show {
            max-height: none;
        }
        .upper-level {
            padding-left: 30px !important;
            height: auto;
        }
    }
}

.menu-item {
    @media (max-width: 991px) {
        display: block !important;
    }
}
