.accordion {
  .card {
    border-bottom: 1px solid $color-body-bg!important;
  }

  .card-header {
    background-color: $color-bg-white;
    padding: 1rem 1.25rem;
    border-bottom: none;

    a {
      text-decoration: none;

      &:hover {
        .icon-chevron-down-16 {
          color: $color-text-primary!important;
        }
      }

      .custom-checkbox {
        opacity: 1;
        transition: opacity 0.1s;
      }

      i {
        transition: transform 0.25s;
      }

      &:not(.collapsed) {
        .custom-checkbox {
          opacity: 0;
        }

        i {
          transform: rotateZ(180deg);
        }
      }
    }
  }
}

/**
 * Tabs to Accordion
 */
.nav,
.panel-title {
  a {
    text-decoration: none;
    margin-bottom: 0;
    padding: 10px;
    background-color: white;
  }
}

@media(min-width: 768px) {
  .panel-heading {
    display: none;
  }

  .panel {
    border: none;
    box-shadow: none;

    .panel-collapse {
      height: auto;

      &.collapse {
        display: block;
      }
    }
  }
}

@media(max-width: 767px) {
  .nav-tabs {
    display: none;
  }

  .tab-content {
    .tab-pane {
      &.active {
        display: block;
      }

      .panel {
        margin: 0;
        box-shadow: none;
        border-radius: 0;
        margin-top: -2px;

        .panel-title a {
          display: block;
        }
      }

      &:first-child {
        .panel {
          border-radius: 5px 5px 0 0;
        }
      }

      &:last-child {
        .panel {
          border-radius: 0 0 5px 5px;
        }
      }
    }
  }
}

/**
 * Accordion group
 */
.accordion-group {
  display: flex;
  flex-flow: row wrap;

  @media (min-width: 992px) {
    margin-right: -15px;
    margin-left: -15px;

    .card {
      background-color: transparent;
    }

    .card-header {
      font-size: 1rem;
      background-color: transparent;
      padding-bottom: .5rem;
      padding-left: 0;
      border-bottom: 1px solid $color-btn-light-bg;

      a {
        cursor: default;

        i {
          display: none;
        }
      }
    }

    .card-body {
      padding-top: 1rem;
      padding-left: 0;
    }

    .collapse {
      &:not(.show) {
        display: block;
      }
    }
  }

  ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    li {
      font-size: .875rem;
      margin-bottom: .5rem;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}