.modal {
    background-color: rgba($color-btn-primary-bg, 0.8);
    overflow-y: scroll;

    .modal-body {
        background-color: $color-body-bg;
        padding: 2rem;
        border: none;
        border-radius: 5px;
    }

    .modal-content {
        border-radius: 5px;
        border: none;
    }

    &.modal-fullscreen {
        background: $color-body-bg;
        @media (min-width: 576px) {
            background-color: rgba($color-btn-primary-bg, 0.8);
        }

        .modal-dialog {
            margin: 0;
            @media (min-width: 576px) {
                display: flex;
                margin: 1.75rem auto;
            }
        }

        .modal-wider {
            @media (min-width: 576px) {
                max-width: 90%;
            }
            @media (min-width: 1200px) {
                max-width: 1100px;
            }
        }

        .modal-content {
            border: none;
            border-radius: 0;
            align-self: flex-start;

            @media (min-width: 576px) {
                border-radius: 5px;
                height: auto;
            }
        }

        .modal-header {
            flex-shrink: 0;
            flex-basis: auto;
        }
    }
}
