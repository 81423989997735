.md-stepper-horizontal {
    display: table;
    width: 100%;
    margin: 0 auto;
}
.md-stepper-horizontal .md-step {
    display: table-cell;
    position: relative;
    padding: 24px;
}
.md-stepper-horizontal .md-step:active {
    border-radius: 15% / 75%;
}
.md-stepper-horizontal .md-step:first-child:active {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.md-stepper-horizontal .md-step:last-child:active {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
    display: none;
}
.md-stepper-horizontal .md-step .md-step-circle {
    width: 35px;
    height: 15px;
    margin: 0 auto;
    border-radius: 50%;
    text-align: center;
    line-height: 15px;
    font-size: 16px;
    font-weight: 600;
}

.md-stepper-horizontal .md-step.done .md-step-circle:before {
    font-family: 'FontAwesome';
    font-weight: 100;
}
.md-stepper-horizontal .md-step.done .md-step-circle *,
.md-stepper-horizontal .md-step.editable .md-step-circle * {
    display: none;
}
.md-stepper-horizontal .md-step.editable .md-step-circle {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.md-stepper-horizontal .md-step.editable .md-step-circle:before {
    font-family: 'FontAwesome';
    font-weight: 100;
    content: '\f040';
}
.md-stepper-horizontal .md-step .md-step-title {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 170px;
}
.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
    text-align: center;
    color: rgba(0, 0, 0, 0.35);
}
.md-stepper-horizontal .md-step.active .md-step-title {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.9);
}
.md-stepper-horizontal .md-step.active.done .md-step-title,
.md-stepper-horizontal .md-step.active.editable .md-step-title {
    font-weight: 600;
}
.md-stepper-horizontal .md-step .md-step-optional {
    font-size: 12px;
}
.md-stepper-horizontal .md-step.active .md-step-optional {
    color: rgba(0, 0, 0, 0.54);
}
.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
    position: absolute;
    top: 32px;
    height: 1px;
    border-top: 2px solid #dddddd;
    &.active {
        border-color: black;
    }
}
.md-stepper-horizontal .md-step .md-step-bar-right {
    right: 0;
    left: 50%;
    margin-left: 10px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
    left: 0;
    right: 50%;
    margin-right: 10px;
}

.icon-circle-16 {
    &.active {
        color: black;
    }
}
