$icomoon-font-family: "Moje-SPP" !default;
$icomoon-font-path: "../fonts/Moje-SPP" !default;

$icon-corner-right: "\e930";
$icon-corner-up: "\e931";
$icon-circle-info-16: "\e932";
$icon-circle-16: "\e92f";
$icon-sort-down-16: "\e92d";
$icon-sort-up-16: "\e92e";
$icon-search-16: "\e92b";
$icon-search: "\e92c";
$icon-book-open-16: "\e900";
$icon-circle-plus-16: "\e901";
$icon-circle-alert-16: "\e902";
$icon-circle-ok-16: "\e903";
$icon-share-16: "\e904";
$icon-attachment-16: "\e905";
$icon-user-16: "\e906";
$icon-user-active-16: "\e907";
$icon-users-16: "\e908";
$icon-check-16: "\e909";
$icon-chevron-top-16: "\e90a";
$icon-chevron-down-16: "\e90b";
$icon-chevron-left-16: "\e90c";
$icon-chevron-right-16: "\e90d";
$icon-arrow-left-16: "\e90e";
$icon-chevron-left: "\e90f";
$icon-chevron-right: "\e910";
$icon-logout: "\e911";
$icon-user: "\e912";
$icon-bell-active: "\e913";
$icon-Times: "\e914";
$icon-bell: "\e915";
$icon-edit-16: "\e916";
$icon-context-points: "\e917";
$icon-settings-active: "\e918";
$icon-settings: "\e919";
$icon-credit-card: "\e91a";
$icon-users: "\e91b";
$icon-briefcase-active: "\e91c";
$icon-book-open: "\e91d";
$icon-chart-bar: "\e91e";
$icon-briefcase: "\e91f";
$icon-clipboard-active: "\e920";
$icon-bars: "\e921";
$icon-clipboard: "\e922";
$icon-file-text-active: "\e923";
$icon-file-text: "\e924";
$icon-map-pin-active: "\e925";
$icon-trending-up: "\e926";
$icon-home-active: "\e927";
$icon-home: "\e928";
$icon-calendar: "\e929";
$icon-map-pin: "\e92a";

