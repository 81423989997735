.bg-primary {
  background-color: $color-nav-bg !important;
}

.bg-secondary {
  background-color: $color-body-bg !important;
}

.bg-dark {
  background-color: $color-btn-primary-bg !important;
}

.bg-light {
  background-color: $color-btn-light-bg !important;
}

.bg-white {
  background-color: $color-bg-white !important;
}

.bg-two-colors {
 // @media (min-width: 768px) {
    background-color: $color-body-bg;
    background-image: -ms-linear-gradient(90deg, $color-body-bg 50%, $color-bg-white 50%);
    background-image: -webkit-linear-gradient(90deg, $color-body-bg 50%, $color-bg-white 50%);
  //}
}