/**
 * Definition list
 */
dl {
    background-color: $color-bg-white;
    border-radius: 5px;
    padding-top: 1.25rem;

    dt {
        color: $color-text-secondary;
        font-size: 14px;
        font-weight: normal;
        padding: 0 1.25rem;
    }

    dd {
        color: $color-text-primary;
        border-bottom: 1px solid $color-body-bg;
        padding: 0 1.25rem 1.25rem 1.25rem;
        margin-bottom: 1.25rem;
    }
}

/**
 * List group
 */
.list-group {
    border-radius: 5px;

    .list-group-item {
        border-color: $color-body-bg;

        padding: 0.5rem 1.25rem;
        .icon-edit-16.disabled {
            opacity: 0.5;
        }
        &:hover {
            .icon-edit-16:not(.disabled) {
                color: $color-text-primary !important;
            }
        }
    }

    strong {
        font-size: 0.875rem;
    }
}

.card-without-border {
    .list-group-item {
        border-width: 0;
    }
}

.attribute-info {
    font-weight: 500;
    font-size: 16px;
    color: black !important;
}

/**
 * Step wizard
 */
.step-wizard {
    min-width: 200px;
    margin-bottom: 0;

    .line {
        width: 100%;
        height: 4px;
        background-color: $color-btn-light-bg;
        display: inline-block;
        border-radius: 0;

        @media (min-width: 992px) {
            height: 2px;
        }
    }

    .step {
        display: none;
        position: absolute;
        top: 0.3rem;
        right: calc(-50% - 0.5rem);
        width: 100%;
        text-align: center;
        color: $color-text-secondary;

        i:before {
            content: $icon-circle-16;
        }
        @media (min-width: 992px) {
            display: block;
        }
    }

    .first-step {
        width: 250px;
        transform: translate(calc(50% + 0.5rem), 0%);
        -ms-transform: translate(53%, 0%);
    }

    li {
        position: relative;
        padding: 0;
        margin-right: 1px;

        @media (min-width: 992px) {
            margin-right: 1rem;
        }

        &:nth-child(1) .line {
            border-radius: 5px 0 0 5px;
        }

        &:last-of-type .line {
            border-radius: 0 5px 5px 0;
        }

        &.active {
            .line {
                background-color: $color-text-primary;
            }

            .step {
                i:before {
                    content: $icon-circle-ok-16;
                }

                color: $color-text-primary;
            }
        }
    }
}
