@import 'src/assets/css/variables/colors';

.table {
    &.table-selectable {
        > tbody > tr {
            background-color: $color-bg-white;
            cursor: pointer;

            &:hover {
                background-color: $color-table-action;
            }
        }
    }
    &.table-action {
        > thead > tr {
            cursor: auto;
        }
    }
}

.row-empty-table {
    cursor: default !important;
    &:hover {
        background-color: #ffffff !important;
    }
    &:before {
        content: none !important;
    }
}
