.container-lg {
    @media (max-width: 991px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.container-login {
    @media (max-width: 991px) {
        margin-left: 15px;
        margin-right: 15px;
    }
}

/**
 * Page header section
 */
.page-header {
    @media (min-width: 768px) {
        background-color: $color-bg-white;
    }
}

/**
 * Desktop columns
 */
.col-left,
.col-right {
    padding-top: 1.5rem;
}

@media (min-width: 768px) {
    .col-left {
        border-right: 1px solid $color-btn-light-bg;
        padding-bottom: 3rem;
        padding-right: 2.5rem;
    }

    .col-right {
        padding-bottom: 3rem;
        padding-left: 2.5rem;
    }
}

hr {
    border-color: $color-btn-light-bg;
}

/**
 * Custom sections
 */
.odpocty {
    .section-spotreba {
        display: none;
    }

    .section-odpocty {
        display: block;
    }
}

.section-odpocty {
    display: none;
}

@media (min-width: 992px) {
    .odpocty .section-spotreba,
    .section-odpocty {
        display: block;
    }
}
