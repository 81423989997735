.carousel-caption {
  position: static;
  color: $color-body-text;
  font-size: .875rem;

  h5 {
    font-size: 1rem;
    color: $color-text-primary;
  }

  p {
    margin-bottom: 2rem;
  }
}

.carousel-indicators {
  margin-top: 1rem;

  li {
    background-color: $color-body-text;
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }
}

.carousel-control-next,
.carousel-control-prev {
  text-decoration: none;
  color: $color-btn-secondary-bg-hover;
  font-size: 2rem;
  transition: .2s ease color;
  align-items: start;

  &:focus,
  &:hover {
    color: $color-body-text;
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background: none;
  width: auto;
  height: auto;
  margin-top: .5rem
}