.help-full-width-strip {
    $offset: 15px;

    position: relative;
    color: $color-text-white;
    background-color: $color-warning;
    padding: $offset 0;

    max-height: 75px;
    overflow-y: hidden;

    transition: max-height 0.3s;

    .help-icon {
        margin-right: $offset + 5;
    }

    .icon-Times {
        &:hover {
            cursor: pointer;
        }
    }

    p {
        font-size: 0.875rem;
    }

    &.expanded {
        max-height: 1000px;

        &:after {
            display: none;
        }
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10px;
        background: linear-gradient(transparent, $color-warning);
        @supports (-webkit-touch-callout: none) {
            background: -webkit-linear-gradient(rgba(255, 255, 255, 0), #f0f0f0);
        }
        @media (min-width: 768px) {
            display: none;
        }
    }

    @media (min-width: 768px) {
        max-height: none;
    }
}
