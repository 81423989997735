.chart-container {
    position: relative;
    width: 100%;
    height: 350px;

    @media (min-width: 576px) {
        width: calc(540px - 30px);
    }

    @media (min-width: 768px) {
        width: calc(720px - 30px);
    }

    @media (min-width: 992px) {
        width: calc(960px - 30px);
    }

    @media (min-width: 1200px) {
        width: calc(1140px - 30px);
    }
}

#consumptionChart {
    background-color: $color-bg-white;
    border-radius: 5px;
}
