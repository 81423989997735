.badge-base {
    // defaults
    background-color: $color-label-ng;
    color: black;
    border: 1px solid #ffeda4;

    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.2px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.3rem;

    &.badge-ec {
        background-color: $color-label-ec;
        color: black;
        border-color: #bbe2fb;
    }

    &.badge-ng {
        background-color: $color-label-ng;
        color: black;
        border-color: #ffeda4;
    }

    &.badge-inactive {
        background-color: transparent;
        color: $color-text-secondary;
        border-color: $color-text-secondary;
    }
}
