.dropdown {
  position: static;

  @media (min-width: 992px) {
    position: relative;
  }

  &.show:after {
    content: "";
    display: block;
    background-color: $color-btn-primary-bg;
    opacity: 0.2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  &.dropdown-place-detail {
    top: 0.5rem;
    right: 1rem;
    position: absolute;
    @media (min-width: 768px) {
      top: auto;
      right: auto;
      position: relative;
    }
  }

  &.dropdown-invoice {
    top: 0.75rem;
    right: 1rem;
    position: absolute;
    @media (min-width: 768px) {
      top: auto;
      right: auto;
      position: relative;
    }
  }
}

.dropdown-menu {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  border: none;
  width: calc(100% - 30px);
  left: 0;
  right: 0;

  @media (min-width: 375px) {
    width: 70vw;
    max-width: 500px;
  }

  &::before {
    color: $color-bg-white;
    display: block;
    content: "";
    border-top: 0;
    border-right: .75rem solid transparent;
    border-bottom: .5rem solid;
    border-left: .75rem solid transparent;
    position: absolute;
    top: -0.5rem;
    left: .75rem;
  }

  &.dropdown-menu-right {
    left: auto;
    @media (min-width: 992px) {
      right: -1rem;
    }

    &::before {
      left: auto;
      right: .5rem;
    }
  }
}

.dropdown-item {
  color: $color-link-hover;
  text-decoration: none;
  font-size: .875rem;
  white-space: normal;

  &:focus,
  &:hover {
    color: $color-link-hover;
    text-decoration: underline;
    background-color: transparent;
  }
}