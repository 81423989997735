/**
 * Buttons
 */
button.close {
    outline: 0;
}

.btn {
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1.75rem;
    border-radius: 3px;
    text-decoration: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    padding: 0.5rem 1.5rem;

    &.btn-lg {
        padding: 0.625rem 2.5rem;
        border-radius: 5px;
    }

    &.btn-sm {
        padding: 0.125rem 0.875rem;
    }
}

.btn-primary {
    background-color: $color-btn-primary-bg;
    border-color: $color-btn-primary-bg;

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus,
    .focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle,
    &.disabled,
    &:disabled {
        background-color: $color-btn-primary-bg-hover;
        border-color: $color-btn-primary-bg-hover;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    }

    &.disabled,
    &:disabled {
        background-color: $color-btn-secondary-bg-hover;
        border-color: $color-btn-secondary-bg-hover;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    }
}

.btn-secondary {
    background-color: $color-btn-secondary-bg;
    border-color: $color-btn-secondary-bg;

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus,
    .focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        background-color: $color-btn-secondary-bg-hover;
        border-color: $color-btn-secondary-bg-hover;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    }

    &.disabled,
    &:disabled {
        background-color: $color-btn-light-bg;
        border-color: $color-btn-light-bg;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    }
}

.btn-light,
.btn-round.btn-light {
    background-color: $color-btn-light-bg;
    border-color: $color-btn-light-bg;
    color: $color-btn-light;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus,
    .focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        background-color: $color-btn-light-bg-hover;
        border-color: $color-btn-light-bg-hover;
        color: $color-btn-light;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    }
}

.btn-link {
    color: $color-btn-link;
    font-size: 15px;
    font-weight: normal;
    text-decoration: none;
    box-shadow: none;
    padding: 0.25rem 0.75rem;

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus,
    .focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $color-link-hover;
        text-decoration: none;
    }

    &:hover {
        > span {
            text-decoration: underline;
        }
    }
}

.btn-round {
    padding: 0.25rem 0.6875rem;
    border-radius: 50%;
    box-shadow: none;

    &.btn-light {
        background-color: $color-btn-light-bg;
        border-color: $color-btn-light-bg;
        color: $color-btn-light;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    }
}

.btn-action {
    padding: 0.25rem 0.5rem;
    border-radius: 50%;
    box-shadow: none;
    font-size: 1.25rem;

    &.dropdown-toggle::after {
        content: none;
    }
}

.btn-inverted {
    background-color: $color-bg-white;
    border-color: $color-bg-white;
    color: $color-link;

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus,
    .focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        background-color: $color-body-bg;
        border-color: $color-body-bg;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

        &.btn-registration {
            background-color: $color-bg-white;
            border-color: $color-bg-white;
        }
    }
}

.btn-consumption-filter {
    text-align: left;
    background-color: $color-bg-white;
    box-shadow: none;
    line-height: 1.5;
    font-weight: normal;
    position: relative;
    border-radius: 0;
    padding: 1rem 1.5rem;
    width: 100%;

    @media (min-width: 992px) {
        border-radius: 3px;
        padding: 0.25rem 1.5rem;
    }

    &:before {
        @include mixin-bg-icon;
        content: $icon-calendar;
        font-size: 1.5rem;
        right: 1rem;
        top: calc(50% - 0.75rem);
    }
}

/**
 * Responsive buttons
 */
.btn-block {
    + .btn-block {
        margin-top: 0;

        &.btn-md-inline {
            margin-top: 0.5rem;

            @media (min-width: 768px) {
                margin-top: 0;
            }
        }
    }
}

@media (min-width: 768px) {
    .btn-md-inline {
        display: inline-block;
        width: auto;
    }

    .btn-md-inline-flex {
        display: inline-flex;
        width: auto;
    }
}

/**
 * Button group
 */
.btn-group {
    background-color: $color-btn-light-bg;
    padding: 0.25rem;
    border-radius: 5px;
    flex-wrap: wrap;

    @media (min-width: 768px) {
        flex-wrap: nowrap;
    }

    > .btn {
        box-shadow: none;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        padding: 0.375rem 1.5rem;

        &:not(:last-child):not(.dropdown-toggle),
        &:not(:first-child) {
            border-radius: 0.25rem;
        }

        &:not(:first-child) {
            margin-left: 0.25rem;
        }

        &.active {
            background-color: $color-bg-white !important;
            color: $color-body-text;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.4);
            color: $color-body-text;
        }
    }
}

/* Shared */
.loginBtn {
    box-sizing: border-box;
    position: relative;
    /* width: 13em;  - apply for fixed size */
    padding: 0 15px 0 46px;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    color: #fff;
    width: 100%;
}
.loginBtn:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
}
.loginBtn:focus {
    outline: none;
}
.loginBtn:active {
    box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
    background-color: #4c69ba;
    background-image: linear-gradient(#4c69ba, #3b55a0);
    /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
    text-shadow: 0 -1px 0 #354c8c;
}
.loginBtn--facebook:before {
    border-right: #364e92 1px solid;
    background: url('/facebook_icon.png') 6px 6px no-repeat;
    background-position: 50%;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
    background-color: #5b7bd5;
    background-image: linear-gradient(#5b7bd5, #4864b1);
}

/* Google */
.loginBtn--google {
    /*font-family: "Roboto", Roboto, arial, sans-serif;*/
    background: #dd4b39;
}
.loginBtn--google:before {
    border-right: #bb3f30 1px solid;
    background: url('/google_icon.png') 6px 6px no-repeat;
    background-position: 50%;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
    background: #e74b37;
}
