/**
 * Card
 */
.card {
    border: none;
    border-radius: 5px;

    &.loading {
        &:after {
            content: '';
            display: block;
            background-color: $color-btn-primary-bg;
            opacity: 0.2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }

        .loading-icon {
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            z-index: 1;
        }
    }

    &.dealer {
        background-image: url('../images/briefcase-gray.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: 105%;
        background-position-y: 70px;
    }

    &.register {
        background-image: url('../images/user-yellow.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: 120%;
        background-position-y: 22px;
    }

    &.card-mobil-transparent {
        background-color: transparent;
        border-color: transparent;

        @media (min-width: 992px) {
            background-color: $color-bg-white;
            border-color: $color-bg-white;
        }
    }
}

a.card:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    .icon-chevron-right-16 {
        color: $color-text-primary !important;
    }
}

/**
 * Card group
 */
.card-group {
    align-items: stretch;
    flex-flow: row wrap;

    .card-group-item {
        padding: 0;
        border-bottom: 1px solid $color-body-bg;
        border-right: 1px solid $color-body-bg;
        display: flex;
        align-items: center;

        &:hover {
            background: $color-table-action;
        }

        a,
        .nav-link {
            display: block;
            width: 100%;
            padding: 1rem 3rem 1rem 1.5rem;
            position: relative;
            font-size: 1rem;
            font-weight: bold;
            text-decoration: none;

            &:after {
                @include mixin-bg-icon;
                content: $icon-chevron-right-16;
                color: $color-text-primary;
                top: calc(50% - 7px);
                right: 1.5rem;
            }

            @media (min-width: 992px) {
                padding: 2rem 4rem 2rem 2rem;

                &:after {
                    right: 2rem;
                }
            }
        }
    }
}
