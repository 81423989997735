/**
 * Text input
 */
.form-group {
    position: relative;
}

.control-label {
    position: absolute;
    pointer-events: none;
    font-size: 1rem;
    letter-spacing: 0;
    left: 1.25rem;
    top: 1rem;
    transition: 0.2s ease all;
    opacity: 1;
    z-index: 10;
    margin: 0;
}

.form-control,
.input-group-text {
    color: $color-input-text;
    background-color: $color-input-bg;
    border-color: $color-input-border;
    border-radius: 5px;
}

.form-control {
    padding: 1.5rem 1.25rem 0.25rem 1.25rem;
    height: calc(1.5em + 1.75rem + 2px);

    &:focus {
        border: 1px solid $color-input-focus;
        box-shadow: 0 0 2px 1px rgba($color-input-focus, 0.75);
    }

    &.is-invalid:focus,
    .was-validated &:invalid:focus {
        border: 1px solid $color-danger;
        box-shadow: 0 0 2px 1px rgba($color-danger, 0.75);
    }

    &.is-valid:focus,
    .was-validated &:valid:focus {
        border: 1px solid $color-success;
        box-shadow: 0 0 2px 1px rgba($color-success, 0.75);
    }

    // For input label effect
    &:not(:placeholder-shown)~.control-label,
    &:focus~.control-label {
        transform: translate(0, -0.5rem);
        font-size: 0.875rem;
        color: $color-text-secondary;
    }

    // For input label effect in IE
    &:not(:-ms-input-placeholder)~.control-label,
    &:focus~.control-label {
        transform: translate(0, -0.5rem);
        font-size: 0.875rem;
        color: $color-text-secondary;
    }
}

// Input group
.input-group {
    border: solid 1px $color-input-border;
    border-radius: 5px;

    .form-control.datepicker {
        border-radius: 5px;
    }

    .input-group-append {
        &~.invalid-feedback {
            padding-right: 40px;
        }
    }

    .input-group-append {
        .btn {
            padding-left: 20px;
        }

        .btn.text-primary {
            padding-left: 0px;
            padding-right: 16px;
        }
    }

    &.is-focused {
        border: 1px solid $color-input-focus;
        box-shadow: 0 0 2px 1px rgba($color-input-focus, 0.75);
        z-index: 0;
    }

    &.is-invalid {
        border: 1px solid $color-danger;
    }

    &.is-valid {
        border: 1px solid $color-success;
    }

    &.is-invalid.is-focused,
    .was-validated &:invalid:focus {
        border: 1px solid $color-danger;
        box-shadow: 0 0 2px 1px rgba($color-danger, 0.75);
    }

    &.is-valid.is-focused,
    .was-validated &:valid:focus {
        border: 1px solid $color-success;
        box-shadow: 0 0 2px 1px rgba($color-success, 0.75);
    }

    input.form-control,
    input.form-control:focus,
    input.form-control:focus {
        border: none;
        box-shadow: none;
        z-index: 0;
    }

    .input-group-append {
        margin-left: 0;

        .btn {
            border: 0;
            background-color: $color-bg-white;
            box-shadow: none;
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }
}

/**
   * Select box
   */
select.form-control {
    border-color: $color-btn-secondary-bg;
    color: $color-input-text;
    border-radius: 5px;
    padding: 1.5rem 2.25rem 0.25rem 1.25rem;
    height: calc(1.5em + 1.75rem + 2px);

    // display none default arrow
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand {
        display: none;
    }

    // custom arrow
    background-image: url('../images/chevron-down.svg');
    background-position: calc(100% - 16px);
    background-size: auto;
    background-repeat: no-repeat;
    margin-right: 16px;

    &.form-control-lg {
        font-size: 1rem;
        line-height: 1.5;
        padding: 0.75rem 2rem 0.75rem 1.25rem;
        height: calc(1.5em + 1.5rem + 2px);
    }
}

/**
   * Checkbox
   */
.custom-checkbox {
    .custom-control-label {
        padding-left: 8px;

        &::before {
            border-color: $color-btn-secondary-bg;
            width: 1.5rem;
            height: 1.5rem;
            top: 50%;
            left: -1.5rem;
            transform: translate(0%, -50%);
        }

        &::after {
            background-image: url('../images/check-white.svg');
            width: 1.5rem;
            height: 1.5rem;
            top: 50%;
            left: -1.5rem;
            transform: translate(0%, -50%);
        }

        &:hover {
            cursor: pointer;
        }
    }

    .custom-control-input {
        &:focus~.custom-control-label::before {
            border-color: $color-btn-secondary-bg;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        }

        &:checked~.custom-control-label::before {
            border-color: $color-input-text;
            background-color: $color-input-text;
        }

        &:not(:checked):hover~.custom-control-label::after {
            background-image: url('../images/check-gray.svg');
        }

        &:disabled:checked,
        &:disabled {
            ~.custom-control-label::before {
                border-color: $color-btn-light-bg;
                background-color: $color-body-bg;
            }

            ~.custom-control-label::after {
                background-image: url('../images/check-gray.svg');
            }
        }

        &:disabled:not(:checked)~.custom-control-label::after {
            background-image: none;
        }
    }
}

/**
   * Radio
   */
.custom-radio {
    &:hover {
        cursor: pointer;
    }

    &:hover>.custom-control-label::after {
        background-color: $color-btn-secondary-bg;
        border-color: $color-btn-secondary-bg;
    }

    .custom-control-input {
        ~.custom-control-label {
            padding-left: 16px;

            &::before {
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                background-image: none;
                top: 0;
                left: -1.25rem;
            }

            &::after {
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background-image: none;
                top: 0.25rem;
                left: -1rem;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.custom-control-input {

    &:checked,
    &:not(:disabled):active {
        ~.custom-control-label::before {
            border-color: $color-btn-secondary-bg;
            background-color: white;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        }
    }

    &:disabled,
    &[disabled] {
        ~.custom-control-label::before {
            border: 1px solid $color-btn-light-bg;
            background-color: $color-body-bg;
            box-shadow: none;
        }
    }
}

.custom-radio {
    .custom-control-input {
        &:not(:checked):hover~.custom-control-label::after {
            background-color: $color-btn-secondary-bg;
            border-color: $color-btn-secondary-bg;
        }

        &:checked~.custom-control-label::after {
            background-image: none;
            background-color: $color-text-primary;
            border-color: $color-text-primary;
        }
    }
}

/**
   * Switch
   */
.custom-switch {
    .custom-control-label {
        padding-right: 4.5rem;
        text-align: right;

        &::before {
            height: 2rem;
            width: 3.5rem;
            right: 0;
            left: auto;
            border-radius: 1rem;
            background-color: $color-btn-light-bg;
            border-color: $color-btn-light-bg;
        }

        &::after {
            top: calc(0.5rem - 2px);
            right: calc(1.75rem - 2px);
            left: auto;
            width: calc(2rem - 4px);
            height: calc(2rem - 4px);
            border-radius: 1rem;
            background-color: $color-text-white;
        }
    }

    .custom-control-input {
        &:focus~.custom-control-label::before {
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        }

        &:checked~.custom-control-label::after {
            transform: translateX(1.5rem);
        }

        &:checked~.custom-control-label::before,
        &:not(:disabled):active~.custom-control-label::before {
            background-color: $color-success;
            border-color: $color-success;
        }
    }

    &.switch-inverse .custom-control-label {
        &::before {
            background-color: $color-text-white;
            border-color: $color-text-white;
        }

        &::after {
            background-color: $color-btn-light-bg;
        }
    }
}

/**
   * Valid and invalid input
   */
.form-control.is-valid,
.was-validated .form-control:valid {
    background-image: url('../images/ok-circle.svg');
    background-position: right 12px center;
}

textarea.form-control.is-valid.is-scrollbar,
.was-validated textarea.is-scrollbar.form-control:valid {
    background-position: right 25px top 10px;
}

.form-control.is-invalid,
.was-invalidated .form-control:valid {
    background-image: none;

    &~.invalid-feedback {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        width: auto;
        margin-top: 0;
    }
}


.check-white {
    background-image: url('../images/check-white.svg');
    background-repeat: no-repeat;
    width: 1rem;
    height: 1rem;
    transform: translate(0%, 25%);
}