@import "variables/moje-spp-icons";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?y7u10j');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?y7u10j#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?y7u10j') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?y7u10j') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?y7u10j##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  //speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-corner-right {
  &:before {
    content: $icon-corner-right;
  }
}
.icon-corner-up {
  &:before {
    content: $icon-corner-up;
  }
}
.icon-circle-info-16 {
  &:before {
    content: $icon-circle-info-16;
  }
}
.icon-circle-16 {
  &:before {
    content: $icon-circle-16;
  }
}
.icon-sort-down-16 {
  &:before {
    content: $icon-sort-down-16;
  }
}
.icon-sort-up-16 {
  &:before {
    content: $icon-sort-up-16;
  }
}
.icon-search-16 {
  &:before {
    content: $icon-search-16;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-book-open-16 {
  &:before {
    content: $icon-book-open-16;
  }
}
.icon-circle-plus-16 {
  &:before {
    content: $icon-circle-plus-16;
  }
}
.icon-circle-alert-16 {
  &:before {
    content: $icon-circle-alert-16;
  }
}
.icon-circle-ok-16 {
  &:before {
    content: $icon-circle-ok-16;
  }
}
.icon-share-16 {
  &:before {
    content: $icon-share-16;
  }
}
.icon-attachment-16 {
  &:before {
    content: $icon-attachment-16;
  }
}
.icon-user-16 {
  &:before {
    content: $icon-user-16;
  }
}
.icon-user-active-16 {
  &:before {
    content: $icon-user-active-16;
  }
}
.icon-users-16 {
  &:before {
    content: $icon-users-16;
  }
}
.icon-check-16 {
  &:before {
    content: $icon-check-16;
  }
}
.icon-chevron-top-16 {
  &:before {
    content: $icon-chevron-top-16;
  }
}
.icon-chevron-down-16 {
  &:before {
    content: $icon-chevron-down-16;
  }
}
.icon-chevron-left-16 {
  &:before {
    content: $icon-chevron-left-16;
  }
}
.icon-chevron-right-16 {
  &:before {
    content: $icon-chevron-right-16;
  }
}
.icon-arrow-left-16 {
  &:before {
    content: $icon-arrow-left-16;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}
.icon-logout {
  &:before {
    content: $icon-logout;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-bell-active {
  &:before {
    content: $icon-bell-active;
  }
}
.icon-Times {
  &:before {
    content: $icon-Times;
  }
}
.icon-bell {
  &:before {
    content: $icon-bell;
  }
}
.icon-edit-16 {
  &:before {
    content: $icon-edit-16;
  }
}
.icon-context-points {
  &:before {
    content: $icon-context-points;
  }
}
.icon-settings-active {
  &:before {
    content: $icon-settings-active;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card;
  }
}
.icon-users {
  &:before {
    content: $icon-users;
  }
}
.icon-briefcase-active {
  &:before {
    content: $icon-briefcase-active;
  }
}
.icon-book-open {
  &:before {
    content: $icon-book-open;
  }
}
.icon-chart-bar {
  &:before {
    content: $icon-chart-bar;
  }
}
.icon-briefcase {
  &:before {
    content: $icon-briefcase;
  }
}
.icon-clipboard-active {
  &:before {
    content: $icon-clipboard-active;
  }
}
.icon-bars {
  &:before {
    content: $icon-bars;
  }
}
.icon-clipboard {
  &:before {
    content: $icon-clipboard;
  }
}
.icon-file-text-active {
  &:before {
    content: $icon-file-text-active;
  }
}
.icon-file-text {
  &:before {
    content: $icon-file-text;
  }
}
.icon-map-pin-active {
  &:before {
    content: $icon-map-pin-active;
  }
}
.icon-trending-up {
  &:before {
    content: $icon-trending-up;
  }
}
.icon-home-active {
  &:before {
    content: $icon-home-active;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-map-pin {
  &:before {
    content: $icon-map-pin;
  }
}

