.popup-buttons {
  position: fixed;
  bottom: 1rem;
  right: 1rem;

  .btn-popup {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  }
}

.popup-chat,
.popup-help {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 570px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  @media (min-width: 576px) {
    right: 1rem;
  }

  .popup-content {
    max-height: 70vh;
    overflow-y: scroll;

    @media (min-width: 576px) {
      max-height: initial;
      overflow-y: unset;
    }
  }

  &.show {
    display: block;
  }
}