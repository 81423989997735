.custom-button-dropdown {
    &.dropdown.show:after {
        display: none;
    }

    > button {
        font-weight: normal;
        box-shadow: none;

        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    > .dropdown-menu {
        z-index: 2;
        max-width: inherit;
        width: calc(100%);

        &[x-placement='top-end'] {
            &:before {
                transform: rotateZ(180deg);
                top: 100%;
            }
        }
        > .dropdown-item {
            outline: none;
        }
    }
}
