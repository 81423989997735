.tooltip-inner {
  max-width: 600px;
}

.tooltip-password {
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;

  p:nth-of-type(2) {
    margin-top: 16px;
    margin-bottom: 0;
  }

  .progress {
    margin-bottom: 8px;
    height: 0.5rem;

    .progress-bar {
      width: 25%;
      height: 0.5rem;
    }
  }

  ul {
    padding-left: 16px;

    li>small {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}