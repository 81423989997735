@import '~bootstrap/scss/bootstrap-grid.scss';
@import 'src/assets/css/variables/colors';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* IE11 - hide clear and reveal password icon */
input::-ms-clear,
input::-ms-reveal {
    display: none;
}

// input[type='search'] {
//     &::-ms-clear {
//         display: inline-block;
//     }
// }

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

div#root {
    min-height: calc(100vh - 82px);
    overflow-x: hidden;

    @media (min-width: 992px) {
        min-height: 100vh;
    }
}

.padding-bottom-200 {
    padding-bottom: 200px;
}

.max-width-300 {
    @media (min-width: 992px) {
        max-width: 300px;
    }
}

.tooltip-password ul li>small {
    display: inline-block !important;
    vertical-align: middle;
    width: 100%;
}

.tooltip.show {
    opacity: 1;
}

.display-large-screen {
    display: none;

    @media (min-width: 992px) {
        display: table-row;
    }

    &::before {
        left: auto;
        right: 0.5rem;
    }
}

.VictoryContainer {
    // background-color: white;
    // border-radius: 5px;
}

.carousel-photo {
    width: 50px;
    height: 50px;
    background-color: #777777;
    border-radius: 50%;
}

.carousel-inner {
    text-align: center;
}

.carousel-caption>p {
    white-space: break-spaces;
}

.spinner {
    border-radius: 50%;
    animation: spin 2s linear infinite;

    &.spinner-large {
        border: 12px solid transparent;
        border-top: 12px solid #ffd500;
        border-bottom: 12px solid #ffd500;
        width: 100px;
        height: 100px;
    }

    &.spinner-medium {
        border: 6px solid transparent;
        border-top: 6px solid #ffd500;
        border-bottom: 6px solid #ffd500;
        width: 40px;
        height: 40px;
    }

    &.spinner-small {
        border: 4px solid transparent;
        border-top: 6px solid #ffd500;
        border-bottom: 6px solid #ffd500;
        width: 1.4rem;
        height: 1.4rem;
    }
}

.loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #fff, $alpha: 0.6);

    display: flex;
    justify-content: center;
    align-items: center;

    &.transparent-background-mask {
        background: transparent;
    }

    &.loading-mask__layer--parent {
        z-index: 1000;
    }

    &.loading-mask__layer--dialog {
        z-index: 2000;
    }

    &.loading-mask__layer--10 {
        z-index: 10;
    }

    &.loading-mask__layer--always-top {
        z-index: 9000;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    &.loading-indicator-fullscreen {
        position: fixed;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

.rotateZ-180 {
    transform: rotateZ(180deg);
}

.smooth-transition {
    transition: transform 0.25s;
}

.clickable-element {
    &.clickable-element--text {
        color: $color-link;
        text-decoration: none;
    }

    &.text-decoration-underline-inverse {
        text-decoration: underline;
    }

    &:hover {
        &.clickable-element--text {
            color: $color-btn-link;
            text-decoration: underline;
        }

        &.text-decoration-underline-inverse {
            text-decoration: none;
        }
    }

    .switch-button {
        color: gray;

        &.active,
        &:hover {
            color: black;
        }
    }
}

.top-down-gradient-effect {
    display: flex;
    bottom: -3rem;
    left: 0px;
    position: absolute;
    height: 160px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(transparent, #f0f0f0, #f0f0f0);
}

.max-height-600-md {
    height: 600px;
    overflow: hidden;
}

@include media-breakpoint-up(md) {
    .max-height-600-md {
        height: auto;
        overflow: auto;
    }
}

.max-height-140 {
    height: 140px;
    overflow: hidden;
}

.focus-highlight-heavy {
    &:focus {
        // border: 1px solid $color-btn-secondary-bg-hover !important;
        box-shadow: 2px 0 2px 2px $color-btn-secondary-bg-hover !important;
    }
}

.focus-highlight-light {
    &:focus {
        box-shadow: 0px 0 2px 1px $color-btn-secondary-bg-hover !important;
    }
}

.light-shadow {
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.25) !important;
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: #d3d3d3;
    border-color: #d3d3d3;
}

.cookies-switch.custom-switch .custom-control-input:not(:disabled) {
    &:checked~.custom-control-label::before {
        background-color: $color-nav-bg;
        border-color: #282828;
    }

    &:not(:checked)~.custom-control-label::before {
        background-color: #282828;
        border-color: #282828;
    }
}

.too-much {
    position: relative;
}

.too-much:after {
    content: ' ';
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background-image: linear-gradient(rgba(255, 255, 255, 0), white);
}

.icon.icon-user {
    height: auto;
    font-weight: bold;

    &:before {
        content: '';
        background-size: contain;
        background-repeat: no-repeat;
        width: 30px;
        display: inline-block;
    }

    &:after {
        content: '';
        display: inline-block;
        background-color: #f0f0f0;
        height: 100%;
        position: absolute;
        top: 0;
        width: 1px;
    }
}

.color-text-primary {
    color: $color-text-primary;
}

.overlay-graph-background {
    background-color: rgba(211, 211, 211, 1);
}

.overlay-graph {
    z-index: 10;
}

.scale-graph {
    z-index: 1;
    // top: 30px;
    // right: 15px;
    // max-width: 70%;
}

.legend-color {
    width: 1em;
    height: 1em;
    margin: 0 1em;
}

.text-cut-dots {
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jodit-toolbar-button_Prida__premenn_ {
    width: 150px;

    button {
        width: 100%;
    }
}

.jodit-toolbar-button_Add_a_variable {
    width: 150px;

    button {
        width: 100%;
    }
}

// ------- IE bug
.jodit-ui-button_insert {
    min-width: 90px !important;
}

.jodit-ui-button_unlink {
    min-width: 140px !important;
}

// -------

.form-control-filter {
    border-color: $color-btn-light-bg !important;
}

.search-input--clear {
    position: absolute;
    top: calc(50% - 10px);
    right: 16px;
    width: auto;
    margin-top: 0;

    color: $color-input-border;
}

@include media-breakpoint-up(sm) {
    .text-sm-transparent {
        color: transparent !important;
    }
}

ol.breadcrumb {
    padding-top: 5px;
    padding-bottom: 0px;

    @media (min-width: 992px) {
        height: 36px;
    }
}

div.top-radius {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

div.no-top-radius {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.navbar-text {
    font-size: 1rem;
}

.consumption-only-reading {
    font-size: 1.8rem;
    border-radius: 25px;
    border: 1px solid;
    padding: 5px;
    border-color: #777777;
    cursor: pointer;
}

.consumption-only-reading-selected {
    background-color: #777777;
    color: white;
}

.card.delivery-point,
.card.card-hoverable {
    text-decoration: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    &:hover {
        background: $color-table-action;
    }
}

.nav-pills-container {
    margin: 0 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .custom-nav-pill {
        background: transparent;
        border-radius: 8px;
        padding: 0.3rem 1.1rem;
        box-shadow: none !important;
        border: none;
        font-size: 1rem;

        &.active {
            background: #ffd500 !important;
        }
    }

    .arrow-up {
        margin-top: 8px;
        width: 0;
        height: 0;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-bottom: 20px solid #f0f0f0;
    }
}

.table {
    >thead {
        >tr:first-child {
            >th {
                border: 0;
            }

            >th:first-child {
                border-top-left-radius: 5px;
            }

            >th:last-child {
                border-top-right-radius: 5px;
            }
        }

        >tr.filter>th {
            padding-bottom: 10px;
            padding-top: 10px;
        }
    }

    >tbody {
        >tr:last-child {
            >td:first-child {
                border-bottom-left-radius: 5px;
            }

            >td:last-child {
                border-bottom-right-radius: 5px;
            }
        }
    }

    &.table-selectable>tbody>tr {
        &.loading {
            background: #d3d3d3;

            &:hover {
                background: darken(#d3d3d3, 10%);
            }
        }

        &:hover {
            .too-much:after {
                background-image: linear-gradient(rgba(255, 255, 255, 0), $color-table-action);
            }
        }
    }
}

.input-group.search-box {
    padding: 7px 0px 5px 10px;
    background: white;

    input.form-control {
        padding: 0;
        height: 1.5rem;
    }

    input {
        font-weight: 500;

        &::placeholder {
            font-weight: normal;
        }
    }

    div.input-group-append>button.btn {
        padding: 0;
        padding-right: 10px;
    }
}

.autocomplete-search-box {
    width: 100%;
    display: block;
    position: relative;
    top: -4px;

    .input-group {
        position: absolute;
        z-index: 1;
        background-color: white;

        .rbt-input-multi.form-control.rbt-input {
            padding: 6px 8px;
            margin: 1px 0 0 1px;
        }

        .input-group-append {
            height: 39px;
            margin-right: 1px;
            margin-top: 1px;

            >button.btn {
                padding: 0;
                padding-left: 13px;
                padding-right: 10px;
            }
        }
    }
}

.export_modal_wider {
    min-width: 700px !important;
}

.table-filter-control-card {
    &.card {
        // border-bottom: 8px solid #ffd500;
        // border-top: 8px solid #ffd500;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    }

    .border-strip {
        background: #ffd500;
        height: 8px;

        &.border-strip--top {
            border-radius: 5px 5px 0 0;
        }

        &.border-strip--bottom {
            border-radius: 0 0 5px 5px;
        }
    }
}

.button-icon {
    font-size: 80%;
}

.cancel-request {
    color: #777777;
}

.cancel-request:hover {
    color: black;
}

.hoverable:hover {
    cursor: pointer;

    .hovered-from-parent {
        text-decoration: underline;
    }
}

.notification-style-reset {
    p {
        font-size: 0.875rem;
        margin-bottom: 0;
    }
}

.modal-header {
    justify-content: normal !important;
}

.invoice-summary-container {
    width: 100vw;

    @media (max-width: 575px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.invoice-summary-transactions {
    @media (max-width: 575px) {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.invoice-summary-item {
    width: 100% !important;

    @media (max-width: 575px) {
        width: 100vw !important;
        border-radius: 0 !important;
    }
}

.invoice-summary-row {
    @media (min-width: 575px) and (max-width: 992px) {
        justify-content: center;
    }
}

.customer-request-step-back {
    color: #777777;
}

.customer-request-step-back:hover {
    color: black;
}

.btn-login {
    @media (min-width: 992px) {
        padding: 0.3rem 1.5rem !important;
    }
}

.rbt-input-multi.disabled {
    background-color: #e9ecef !important;
    opacity: 1;
}

.delivery-point-switch {
    .delivery-point-switch-button {
        padding: 10px 24px;

        text-transform: none !important;
        line-height: 1.5;

        &:not(.active) {
            background: #e6e6e6;
        }
    }

    .delivery-point-switch-select {
        height: 56px;

        border-radius: 6px;
        line-height: 1.5;

        box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.15);
    }

    @include media-breakpoint-up(md) {
        .delivery-point-switch-select {
            box-shadow: none;
        }
    }
}

.delivery-point-switch-modal-button {
    >button {
        text-transform: none;
        line-height: 1.5;
        box-shadow: none;
        border-radius: 6px;
    }

    >.selection-dot {
        position: absolute;
        background: black;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        right: 24px;
        top: calc(50% - 4px);
    }
}

.checkbox-active-and-inactive {
    .custom-control-label::before {
        background-color: #777777 !important;
    }
}

.stepper {
    div {
        position: relative;
        margin: 0 0 3rem 0;
        width: 100%;
        align-items: center;

        &:not(:last-child)::before {
            .active {
                border: 2px solid blue;
            }

            display: block;
            position: absolute;
            content: '';
            border: 1px solid #495057;
            top: 100%;
            height: 3rem;
            left: 8px;
        }
    }
}

.tariff-select-dp-name {
    text-align: center;

    span {
        font-size: 18px;
        background-color: black;
        color: white;
        border-radius: 5px;
        padding: 2px 5px;
    }
}

.font-weight-500 {
    font-weight: 500 !important;
}

.form-cancel-button {
    position: absolute;
    right: 0px;
    top: 0px;
    transform: translate(-75%, 50%);
}

.skip-login-button {
    width: 280px;
    border: 2px solid black;
    padding: 0.625rem 2.5rem;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1.75rem;
    color: black;

    &:hover {
        cursor: pointer;
        border-color: #4a4a4a;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    }
}

.customer-request-new-customer-fields {
    max-width: 80%;

    @media (max-width: 992px) {
        max-width: 100%;
    }
}

.request-card-container {
    padding-left: 0.4rem;
    padding-right: 0.4rem;

    @media (max-width: 992px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    .request-card {
        border-radius: 7px;
        height: 300px;
        background-color: white;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);

        .product-cover {
            height: 45%;
            width: 100%;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 7px 7px 0px 0px;
        }
    }
}

.postion-absolute-bottom {
    position: absolute;
    bottom: 0px;
}

.select-request-button {
    padding: 0.25rem 0.8rem !important;
    margin-bottom: 1.9rem !important;
}

.filter-notifications-div-select {
    margin-bottom: 2px;

    &>select {
        height: 42px;
        padding-top: 0.5rem;
    }
}

.action-edit:hover {
    .pen-component:not(.disabled) {
        background-color: #ffd500;
        border-radius: 50%;
        color: black;
    }
}

.pen-component {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;

    &:hover:not(.disabled) {
        background-color: #ffd500;
        border-radius: 50%;
        color: black;
    }
}

.text-black {
    color: black !important;
}

.font-size16 {
    font-size: 16px;
}

.btn-outline-danger {
    color: #dc3545;
    border-color: #ffe9e9;
    background-color: white;
    border-width: 0.125rem;
    box-shadow: none !important;

    &:hover {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545;
        box-shadow: none !important;
    }

    &:not(:disabled):not(.disabled):active {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545;

        &:focus {
            box-shadow: none !important;
        }
    }
}

.btn-outline-primary {
    color: #0b0b0b;
    border-color: #0b0b0b;
    border-width: 0.125rem;
    box-shadow: none !important;

    &:hover,
    &:focus {
        color: #fff;
        background-color: #4a4a4a;
        border-color: #4a4a4a;
        box-shadow: none !important;
    }

    &:not(:disabled):not(.disabled):active {
        color: #fff;
        background-color: #4a4a4a;
        border-color: #4a4a4a;

        &:focus {
            box-shadow: none !important;
        }
    }
}

.btn-outline-green {
    color: #ffffff;
    border-color: #ffffff;
    border-width: 0.125rem;
    box-shadow: none !important;

    &:hover,
    &:focus {
        color: #000000;
        background-color: #ffffff;
        border-color: #ffffff;
        box-shadow: none !important;
    }

    &:not(:disabled):not(.disabled):active {
        color: #000000;
        background-color: #ffffff;
        border-color: #ffffff;

        &:focus {
            box-shadow: none !important;
        }
    }
}

.bg-success-toast-spp-color {
    background-color: #ffd500 !important;
}

.request-in-process-arrow-up {
    margin-left: 12px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #ffd500;
}

.bg-spp-color {
    background-color: #ffd500 !important;
}

.disconnect-text {
    font-weight: 500;
    font-size: 1.15em;

    &:hover {
        text-decoration: underline;
    }
}

div.vertical-line {
    border-left: 0.25rem;
    border-left-style: solid;
    border-left-color: $color-nav-bg;
    padding-left: 1rem;
}

.color-info {
    color: $color-info;
}

.font-weight-500 {
    font-weight: 500;
}

.custom-control-label-switch-button {
    transform: scale(0.8);
    height: 35px;
}

.text-blue {
    color: #177EE6 !important
}

.dropdownMenuButtons {
    .dropdown-item:not(:last-child) {
        border-bottom: 1px solid #dee2e6 !important;
    }
}

.text-transform-none {
    text-transform: none !important;
}

.news-information-card {
    background-color: #ffd500;
    color: black;
    padding: 0.5rem 1.25rem;
    font-size: 16px;

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 1rem;
        text-shadow: none;
        opacity: 1;
        color: black;

        &:hover {
            color: white;
        }
    }

    &>ul {
        max-width: 90%;
        padding: 0.5rem 1.25rem;
    }
}

.business-partner-notification-card-body {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.notification-spp-left-border {
    border-left: thick solid #ffd500;
    border-bottom: none;
    border-radius: 0px !important;
}

.marketing-consent-recommended {
    background-color: #ffd500;
    border-radius: 25px;
    font-size: 11px !important;
    padding: 3px;
    padding-bottom: 3px;
}

.pre-registration {
    max-width: 915px;
    border-radius: 10px;
    padding: 0;
    color: #000;
    width: 95%;

    @media (min-width: 992px) {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
    }

    &>div {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        min-height: 600px;
    }

    .bg-trees {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .features-holder {
        background-color: rgba(0, 0, 0, 0.55);
        color: #ffffff;
        padding: 25px 30px 20px 30px;
        position: relative;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        min-height: 320px;
        height: 100%;
    }
}