.alert-success,
.alert-success:hover,
.alert-danger,
.alert-danger:hover {
  color: $color-text-white;
  border-radius: 5px;
}

.alert-success {
  background-color: $color-success;
  border-color: $color-success;
}

.alert-danger {
  background-color: $color-danger;
  border-color: $color-danger;
}

@media (min-width: 992px) {
  .alert-success,
  .alert-danger {
    background-color: $color-bg-white;
    border-color: $color-bg-white;
  }

  .alert-success,
  .alert-success:hover,
  .alert-success h3 {
    color: $color-success;
  }

  .alert-danger,
  .alert-danger:hover,
  .alert-danger h3 {
    color: $color-danger;
  }
}

.bg-primary + .alert {
  margin-bottom: 0;
  border-radius: 0;
}