.cookies-alert {
    padding-left: 64px;
    padding-right: 64px;
    box-shadow: 0 -0.3125rem 15.625rem 15.625rem #00000040;

    @media (max-width: 992px) {
        padding-left: 40px;
        padding-right: 40px;
    }

    &>p {
        font-size: 17px;
        color: #282828;

        @media (max-width: 992px) {
            font-size: 14px;
        }
    }
}

.cookies-buttons-container {
    gap: 1rem;
    align-items: center;
    max-width: 50rem;
    flex-wrap: wrap;

    &>.buttons {
        display: grid;
        justify-content: center;
        align-items: center;
        height: -moz-fit-content;
        height: fit-content;
        width: -moz-fit-content;
        width: fit-content;
        box-shadow: none;
        color: #524400;
        text-decoration: none;
        font-size: 1.0625rem;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: .01875rem;
        padding: 0.9375rem 3.5rem;
        border: none;
        border-radius: 4.0625rem;
        white-space: nowrap;
        position: relative;
        background-color: #ffd500;
        transition: all .25s ease-in-out;
        will-change: auto;
        cursor: pointer;
        outline: none;
        flex: 1;

        &:hover {
            background-color: #fbe67b;
            box-shadow: none;
        }
    }
}

.font-size17 {
    font-size: 17px;
}

.cookies-yellow-circle {
    background-color: #ffd500;
    border-radius: 25px;
    font-size: 14px !important;
    padding: 5px;
}

.cookies-grey-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 1.25rem;
    min-width: 1.25rem;
    background-color: #bababa;
    border-radius: 1.25rem;
    font-size: .75rem;
}

.cookies-settings-modal {
    max-width: 56.25rem;

    .body-bg-white {
        background-color: white;
    }

    .tabs {
        display: flex;
        gap: 1rem;

        &>div {
            display: grid;
            justify-content: center;
            align-items: center;
            flex: 1;
            position: relative;
            padding-bottom: 8px;
            font-size: 1.0625rem;
            font-weight: 500;

            &:hover {
                cursor: pointer;
            }
        }

        .active {
            border-bottom: .1875rem solid #ffd500;
        }
    }
}

.cookies-clickable {
    text-decoration-thickness: 0.1875rem !important;
    text-decoration-color: #ffd500 !important;
    text-underline-offset: 0.3rem !important;
}

.cookies-section-border {
    border: 1px solid rgba(0, 0, 0, 0.1)
}

.cookies-section-detail {
    background-color: #efefef;

}

.cookies-external-link {
    margin-left: -17px;
}

.set-cookies::after {
    @include mixin-bg-icon;
    content: $icon-chevron-right-16;
    transform: translate(50%, -50%);
    top: 50%
}