.icon {
  content: "";
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  display: inline-block;
}

.icon-facebook {
  height: 15px;

  &:before {
    @extend .icon;
    text-align: left;
    width: 30px;
    height: 15px;
    background-image: url("../images/facebook-gray.svg");

  }

  &:after {
    content: "";
    display: inline-block;
    background-color: $color-body-bg;
    height: 100%;
    position: absolute;
    top: 0;
    width: 1px;
  }
}

.btn:hover .icon-facebook:before,
.btn:focus .icon-facebook:before {
  background-image: url("../images/facebook.svg");
}

.icon-mail {
  height: 15px;

  &:before {
    @extend .icon;
    text-align: left;
    width: 30px;
    height: 15px;
    background-image: url("../images/mail-gray.svg");

  }

  &:after {
    content: "";
    display: inline-block;
    background-color: $color-body-bg;
    height: 100%;
    position: absolute;
    top: 0;
    width: 1px;
  }
}

.btn:hover .icon-mail:before,
.btn:focus .icon-mail:before {
  background-image: url("../images/mail.svg");
}

.icon-phone {
  height: 15px;

  &:before {
    @extend .icon;
    text-align: left;
    width: 30px;
    height: 15px;
    background-image: url("../images/smartphone-gray.svg");

  }

  &:after {
    content: "";
    display: inline-block;
    background-color: $color-body-bg;
    height: 100%;
    position: absolute;
    top: 0;
    width: 1px;
  }
}

.btn:hover .icon-phone:before,
.btn:focus .icon-phone:before {
  background-image: url("../images/smartphone.svg");
}
